<template>
  <b-container>
    
    <div class="text-center mb-5">
      <h2 class="fw-bold text-uppercase">
        What clients get 
      </h2>
      <h3 class="subtitle_box">
        All our clients get more that working hours of the team.<br> 
        We achieve clients’ goals.
      <br>
      <br>
      </h3>
    </div>
    

    <b-row>
      <b-col sm="12">
        <div class="content-card align-self-center d-flex" v-bind:style="{ backgroundImage: 'url(' + require(`../assets/img/cards/cast2.png`) + ')' }">
          <div class="mt-auto my-lg-auto ms-lg-50 text-center text-lg-start">
            <div class="title">
              Holistic Approach
            </div>
            <div class="h5 mt-3">
              At Connect Digital Media group, we believe in the power of design and technology. Building your site or app is only the first step. We also provide superior UX and performance services to ensure people love your product! 
            </div>
          </div>
        </div>
      </b-col>
    </b-row>

    <b-row>
      <b-col sm="12" >
        <div class="content-card mirrored align-self-center d-flex" v-bind:style="{ backgroundImage: 'url(' + require(`../assets/img/cards/complete2.png`) + ')' }">
          <div class="mt-auto my-lg-auto me-lg-50 text-center text-lg-start">
            <div class="title">
              Profit & Result
            </div>
            <div class="h5 mt-3">
              Connect Digital Media group helps you take your business to the next level online by getting your products off the ground with an awesome website or web application.
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    
  </b-container>
</template>

<script setup lang="ts">
</script>
