export class SocialNetworks {
    public telegram:string
    public twitter:string
    public discord:string
    public linkedin:string
    public email:string
    public phone:string

    constructor(telegram:string, twitter:string, discord:string, linkedin:string, email:string, phone:string) {
        this.telegram = telegram
        this.twitter = twitter
        this.discord = discord
        this.linkedin = linkedin
        this.email = email
        this.phone = phone
    }

}