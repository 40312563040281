<template>
  <b-container>
    <b-row class="border-top py-4 m-0 align-items-center">
      <b-col cols="12" lg="6" class="pb-4 pt-3 text-center text-lg-start text-uppercase p-lg-0">
        CD Media Group 2023
      </b-col>
      <b-col cols="12" lg="6" class="text-center text-uppercase text-lg-end p-0">
      
        <a class="mx-3 me-0" a href="/privacy.html">Privacy policy</a>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "SiteFooter"
}
</script>

<style scoped>

</style>