
import { Options, Vue } from 'vue-class-component';
import Menu from "@/components/MainMenu.vue";
import MainMenu from "@/components/MainMenu.vue";
import MainBanner from "@/components/MainBanner.vue";
import ActivitiesSlider from "@/components/ActivitiesSlider.vue";
import InformationCards from "@/components/InformationCards.vue";
import InformationCardsW from "@/components/InformationCardsW.vue";
import InvestorsAndPartners from "@/components/InvestorsAndPartners.vue";
import OurTeam from "@/components/OurTeam.vue";
import RoadmapStrategy from "@/components/RoadmapStrategy.vue"
import ContactUs from "@/components/ContactUs.vue";
import SiteFooter from "@/components/SiteFooter.vue";
import {RoadMapPoint} from "@/objects/RoadMapPoint";
import {TeamMember} from "@/objects/TeamMember";
import {SlideInfo} from "@/objects/SlideInfo";
import {MenuItem} from "@/objects/MenuItem";
import {SocialNetworks} from "@/objects/SocialNetworks";
@Options({
  components: {
    SiteFooter,
    ContactUs,
    InformationCards,
    InformationCardsW,
    ActivitiesSlider,
    MainBanner,
    MainMenu,
    Menu,
    OurTeam,
    InvestorsAndPartners,
    RoadmapStrategy
  },
})


//TODO: Fix title in roadmap

export default class App extends Vue {
  // Social networks
  socialNetworks: SocialNetworks = new SocialNetworks(
      'https://t.me/cdmediagroup',
      'https://twitter.com/cdmedia_group',
      'link-placeholder',
      'https://www.linkedin.com/company/connect-digital-media-group',
      'mailto:hello@cdmedia.io',
      '423-963-2478'
  )

  // Main menu items
  mainMenu:MenuItem[] = [
    {
      title: 'About Us',
      link: '#about-project'
    },
    {
      title: 'Our team',
      link: '#our-team'
    },
    {
      title: 'Workflow',
      link: '#roadmap'
    },
  ]

  //PROJECT SLIDES
  projectSlides:SlideInfo[] = [
    {
      title: '<div style="margin-top: -2.25rem">WEB DEVELOPMENT<div>',
      rank: '8',
      award: ' ',
      background_url: 'img_slider_01.jpg'
    },
    {
      title: '<div style="margin-top: -2.25rem">WEB DESIGN<div>',
      rank: '7',
      award: ' ',
      background_url: 'img_slider_02.jpg'
    },
    {
      title: '<div style="margin-top: -2.25rem">UI/UX DESIGN<div>',
      rank: '16',
      award: ' ',
      background_url: 'img_slider_03.jpg'
    },
    {
      title: '<div style="margin-top: -2.25rem">MOBILE APP DEVELOPMENT<div>',
      rank: '7',
      award: ' ',
      background_url: 'img_slider_04.jpg'
    },
    {
      title: '<div style="margin-top: -2.25rem">MACHINE LEARNING<div>',
      rank: '4',
      award: ' ',
      background_url: 'img_slider_05.jpg'
    },
    {
      title: '<div style="margin-top: -2.25rem">COMPUTER VISION<div>',
      rank: '9',
      award: ' ',
      background_url: 'img_slider_06.jpg'
    },
    {
      title: '<div style="margin-top: -2.25rem">ARTIFICIAL INTELIGENCE<div>',
      rank: '5',
      award: ' ',
      background_url: 'img_slider_07.jpg'
    },
    {
      title: '<div style="margin-top: -2.25rem">PRODUCT DEVELOPMENT<div>',
      rank: '11',
      award: ' ',
      background_url: 'img_slider_08.jpg'
    },
    {
      title: '<div style="margin-top: -2.25rem">PRODUCT SOURCING<div>',
      rank: '8',
      award: ' ',
      background_url: 'img_slider_09.jpg'
    },
    {
      title: '<div style="margin-top: -2.25rem">QUALITY CONTROL<div>',
      rank: '12',
      award: ' ',
      background_url: 'img_slider_10.jpg'
    },
  ]

  // TEAM MEMBERS
  teamMembers:TeamMember[] = [
    {
      firstName: "Ben",
      lastName: "Arnold",
      position: "Founder",
      background: "ben.jpg"
    },
    {
      firstName: "Lana",
      lastName: "Tara",
      position: "Project Manager",
      background: "sveta.jpg"
    },
    {
      firstName: "Oleg",
      lastName: "Apet",
      position: "Senior UI/UX Designer",
      background: "oleg.jpg"
    },
    {
      firstName: "Alex",
      lastName: "Lishai",
      position: "Senior Android Developer",
      background: "alex.jpg"
    },
    {
      firstName: "Taras",
      lastName: "Zakrevski",
      position: "Senior Web Developer",
      background: "taras.jpg"
    },
    {
      firstName: "Eugene",
      lastName: "Cedrik",
      position: "Senior iOS Developer",
      background: "eugene.jpg"
    },
  ]

  // ROADMAP
  roadMapPoints: RoadMapPoint[] = [
    {
      id: 1,
      date: '',
      title: 'Investigating your needs and project goals',
      description: '',
      isCompleted: true
    },
    {
      id: 2,
      date: '',
      title: 'Discussing requirements and budget',
      description: '',
      isCompleted: false
    },
    {
      id: 3,
      date: '',
      title: 'Delivery a Project Cost',
      description: '',
      isCompleted: false
    },
    {
      id: 4,
      date: '',
      title: 'Time Estimation',
      description: '',
      isCompleted: false
    },
    {
      id: 5,
      date: '',
      title: 'Develop a Project plan with milestones and regular delivery dates',
      description: '',
      isCompleted: false
    },
    {
      id: 6,
      date: '',
      title: 'Project development',
      description: '',
      isCompleted: false
    },
    {
      id: 7,
      date: '',
      title: 'Work on revisions as needed based on your feedback',
      description: '',
      isCompleted: false
    },
    {
      id: 8,
      date: '',
      title: 'Project completion with delivery of final product',
      description: '',
      isCompleted: false
    },
    {
      id: 9,
      date: '',
      title: 'Ongoing Support',
      description: '',
      isCompleted: false
    },





  ]
}

