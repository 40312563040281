<template>
  <b-container>
    <div class="text-center mb-5">
      <h2 class="fw-bold text-uppercase">
        Partners
      </h2>
      <h3 class="subtitle_box">
      </h3>
    </div>
    <b-row>
      <TeamMemberCard firstname="Connect" lastname="Outdoors" position="www.connectoutdoors.co" background="partners/img_partner01.jpg" primary-color="#F3C976" secondary-color="#F3C976"></TeamMemberCard>
      <TeamMemberCard firstname="Connect" lastname="Scale" position="www.connectscale.com" background="partners/img_partner02.jpg" primary-color="#F3C976" secondary-color="#F3C976"></TeamMemberCard>
      <TeamMemberCard firstname="Equotech" lastname="Technology" position="www.equotech.eu" background="partners/img_partner03.jpg" primary-color="#F3C976" secondary-color="#F3C976"></TeamMemberCard>
    </b-row>
    <b-row>
      <TeamMemberCard firstname="Cove Ridge" lastname="Marina and Resort" position="www.coveridgemarina.com" background="partners/img_partner04.jpg" primary-color="#F3C976" secondary-color="#F3C976"></TeamMemberCard>
      <TeamMemberCard firstname="" lastname="DizzOMA.Pro" position="www.dizzoma.com" background="partners/img_partner05.jpg" primary-color="#F3C976" secondary-color="#F3C976"></TeamMemberCard>
      <TeamMemberCard firstname="" lastname="IndicaOnline" position="www.indicaonline.com" background="partners/img_partner06.jpg" primary-color="#F3C976" secondary-color="#F3C976"></TeamMemberCard>
    </b-row>
    <b-row class="my-4">
    </b-row>
  </b-container>
</template>

<script>
import TeamMemberCard from "@/components/TeamMemberCard.vue";
export default {
  name: "InvestorsAndPartners",
  components: {
    TeamMemberCard
  }

}
</script>

<style scoped>

</style>