<template>
  <b-container>
    
    <div class="text-center mb-5">
      <h2 class="fw-bold text-uppercase">
        Why work with us? 
      </h2>
      <h3 class="subtitle_box">
        You want a digital solution that works. You want to make your business processes effective, 
        sell more products, get more customers and make more money. We can help.
      <br>
      <br>
      </h3>
    </div>
    

    <b-row>
      <b-col sm="12">
        <div class="content-card align-self-center d-flex" v-bind:style="{ backgroundImage: 'url(' + require(`../assets/img/cards/cast-vote-background-full-size.png`) + ')' }">
          <div class="mt-auto my-lg-auto ms-lg-50 text-center text-lg-start">
            <div class="title">
              Experienced Team
            </div>
            <div class="h5 mt-3">
              Our team is passionate about what we do, and that passion transmits to our work. You will feel pride as soon as you see your work come to life. 
            </div>
          </div>
        </div>
      </b-col>
    </b-row>

    <b-row>
      <b-col sm="12" >
        <div class="content-card mirrored align-self-center d-flex" v-bind:style="{ backgroundImage: 'url(' + require(`../assets/img/cards/complete-and-get-coins.png`) + ')' }">
          <div class="mt-auto my-lg-auto me-lg-50 text-center text-lg-start">
            <div class="title">
              Efficiency
            </div>
            <div class="h5 mt-3">
              Revolutionize your business with a web solution or a mobile app that delivers more customers to you. Your brand, your message, your product can all be made available to your customers 24/7.
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="px-2" sm="12" lg="6">
        <div class="content-card small align-items-baseline d-flex flex-column" v-bind:style="{ backgroundImage: 'url(' + require(`../assets/img/cards/complete-and-get-access.png`) + ')' }">
          <b-img src="./../assets/img/cards/key-icon.png" width="90"></b-img>
          <div class="title fw-bolder h5 mt-auto text-white">
            We speak your language
          </div>
          <div class="subtitle mt-3">
            We are here to help you realize your products or services. Whether it be a website, mobile application or anything in between. Our team will help shape your product into the best possible version.
          </div>
        </div>
      </b-col>
      <b-col sm="12" lg="6" class="px-2">
        <div class="content-card small align-items-baseline d-flex flex-column" v-bind:style="{ backgroundImage: 'url(' + require(`../assets/img/cards/become-a-mentor.png`) + ')' }">
          <b-img src="./../assets/img/cards/person-icon.png" class="align-self-auto" width="90"></b-img>
          <div class="title fw-bolder h5 mt-auto text-white">
            Business Result
          </div>
          <div class="subtitle mt-3">
            We’ve worked hard to build a solid, smooth-running team and we are proud of the results.<br>  
            Our process is effective, our people are skilled and our clients meet their business goals.
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script setup lang="ts">
</script>
